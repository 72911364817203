import {
  Box,
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  tableCellClasses,
  IconButton,
  CircularProgress,
  Button,
} from '@mui/material';
import { palette } from 'theme/contants';
import { Icon } from '@iconify/react';
import { styles } from '../../styles';
import { useState, useRef, useEffect } from 'react';
import { ClientCard } from '../Card';
import { MuiModal, TextField } from 'components';
import { useForm } from 'react-hook-form';
import { UseIntermitence } from 'hooks';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector, useDispatch } from 'react-redux';
import { productsSelector } from 'store/selectors';
import { getProductImage, getSavingProducts, resetImage, updateProductStatus } from 'store/actions';
import { parseAmount } from 'utils';
import { Pagination } from 'components';
import { UsePagination } from 'hooks';

import { StatusEnum } from 'utils/constants';

export const ProductsData = ({ clientId }: any) => {
  const dispatch = useDispatch();
  const [selectedProduct, setSelectedProduct]: any = useState('');
  const [showButton, setShowButton]: any = useState(true);
  const products = useSelector(productsSelector);
  const { status, switchStatus } = UseIntermitence();
  const inputRef = useRef(null);

  const {
    productsData: { data, meta },
    currentImage,
    loading,
  } = products;
  const totalPages = meta?.pageCount || 1;

  const { nextPage, previousPage, page } = UsePagination(meta?.page, totalPages);

  const headers: string[] = [
    'Nombre del producto o servicio',
    'Tipo',
    'Disponibilidad',
    'Ubicación de origen',
    'Teléfono',
    'Asociación que ofrece el producto',
    'Correo',
    'Precio',
  ];

  const headersInit: string[] = [
    'Nombre del producto o servicio',
    'Tipo',
    'Estado',
    'Disponibilidad',
    'Ubicación de origen',
    'Teléfono',
    'Asociación que ofrece el producto',
    'Precio',
    '',
  ];

  const {
    register,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      description: selectedProduct.description,
    },
    resolver: yupResolver(Yup.object().shape({ description: Yup.string() })),
  });

  useEffect(() => {
    dispatch(getSavingProducts(clientId, page));
    if (selectedProduct?.description) setValue('description', selectedProduct?.description);

    return () => {
      setValue('description', '');
      dispatch(resetImage());
    };
  }, [page]);

  const handleShowImage = (url: string) => {
    dispatch(getProductImage({ key: url }));
    switchStatus();
  };

  const setReviewStatus = (status: string) => {
    dispatch(
      updateProductStatus(selectedProduct?.id, status, page, clientId, ({ ok }) => {
        if (ok) {
          setShowButton(false);
        }
      }),
    );
    setShowButton(true);
  };

  const translateStatus = (status: string) => {
    if (status == 'Waiting') return 'Por Verificar';
    if (status == 'Approved') return 'Aprobado';
    if (status == 'Rejected') return 'Rechazado';
  };

  return (
    <Box display='flex' minHeight='100%' position='relative'>
      {selectedProduct == '' ? (
        <Box marginBottom={'1rem'} width={'100%'}>
          {data && data.length ? (
            <Table
              sx={{
                bgcolor: palette.white,
                borderRadius: '0.625rem',
                width: '98%',
                boxShadow: 2,
                marginLeft: '1%',
                marginRight: '1%',
                marginBottom: '2rem',
                position: 'relative',
              }}
              aria-label='simple table'>
              <TableHead
                sx={{
                  width: '52.5rem',
                  [`& .${tableCellClasses.root}`]: {
                    padding: '1rem',
                  },
                }}>
                <TableRow
                  sx={{
                    [`& .${tableCellClasses.root}`]: {
                      paddingTop: '0.6rem',
                      paddingBottom: '0.6rem',
                      border: 'none',
                      bgcolor: palette.dark,
                      borderRadius: '0.438rem',
                    },
                  }}>
                  <TableCell align={'left'} width={'100%'} colSpan={12}>
                    <Typography fontWeight={'600'} color={palette.white} fontSize={'0.75rem'}>
                      Productos y servicios de la caja
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    [`& .${tableCellClasses.root}`]: {
                      paddingTop: '0.5rem',
                      paddingBottom: '0.5rem',
                      borderBottom: `1px solid ${palette.gray}`,
                    },
                  }}>
                  {headersInit?.map((item: string, index: number | string) => (
                    <TableCell width={`${100 / headers.length}%`} key={index}>
                      <Typography fontSize={'0.75rem'} fontWeight='700'>
                        {item}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {data?.length > 0 ? (
                  <>
                    {data?.map((row: any, index: number) => (
                      <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component='th' scope='row'>
                          <Typography fontSize={'0.875rem'} sx={styles.rowText}>
                            {row.name}{' '}
                          </Typography>
                        </TableCell>
                        <TableCell component='th' scope='row'>
                          <Typography fontSize={'0.875rem'} sx={styles.rowText}>
                            {row.classification}{' '}
                          </Typography>
                        </TableCell>
                        <TableCell component='th' scope='row'>
                          <Typography fontSize={'0.875rem'} sx={styles.rowText}>
                            {translateStatus(row.productStatus)}{' '}
                          </Typography>
                        </TableCell>
                        <TableCell component='th' scope='row'>
                          <Typography fontSize={'0.875rem'} sx={styles.rowText}>
                            {row.availability}{' '}
                          </Typography>
                        </TableCell>
                        <TableCell component='th' scope='row'>
                          <Typography fontSize={'0.875rem'} sx={styles.rowText}>
                            {row.location}{' '}
                          </Typography>
                        </TableCell>
                        <TableCell component='th' scope='row'>
                          <Typography fontSize={'0.875rem'} sx={styles.rowText}>
                            {row.phone}{' '}
                          </Typography>
                        </TableCell>
                        <TableCell component='th' scope='row'>
                          <Typography fontSize={'0.875rem'} sx={styles.rowText}>
                            {row.association}{' '}
                          </Typography>
                        </TableCell>
                        <TableCell component='th' scope='row' sx={{}}>
                          <Typography marginRight={'2rem'} fontSize={'0.875rem'} sx={styles.rowText}>
                            {parseAmount(row.price)}
                          </Typography>
                        </TableCell>
                        <TableCell component='th' scope='row' align='center'>
                          <IconButton
                            onClick={() => {
                              setSelectedProduct(row);
                            }}>
                            <Icon icon='ic:baseline-remove-red-eye' width={18} color={palette.black} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                ) : (
                  <TableRow
                    sx={{
                      height: '1rem',
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}>
                    <TableCell align='center' colSpan={12}></TableCell>
                  </TableRow>
                )}
              </TableBody>
              <Box width={'98%'} position='absolute' bottom={-40} padding={'0 1%'}>
                <Pagination next={nextPage} previous={previousPage} page={page} totalPages={totalPages} actualQty={1} />
              </Box>
            </Table>
          ) : (
            <EmptyState loading={loading} />
          )}
        </Box>
      ) : (
        <Box marginBottom={'1rem'} width={'100%'}>
          <Table
            sx={{
              bgcolor: palette.white,
              borderRadius: '0.625rem',
              width: '98%',
              boxShadow: 2,
              marginLeft: '1%',
              marginRight: '1%',
              marginBottom: '2rem',
            }}
            aria-label='simple table'>
            <TableHead
              sx={{
                width: '52.5rem',
                [`& .${tableCellClasses.root}`]: {
                  padding: '1rem',
                },
              }}>
              <TableRow
                sx={{
                  [`& .${tableCellClasses.root}`]: {
                    paddingTop: '0.6rem',
                    paddingBottom: '0.6rem',
                    border: 'none',
                    bgcolor: palette.dark,
                    borderRadius: '0.438rem',
                  },
                }}>
                <TableCell align={'left'} width={'100%'} colSpan={12}>
                  <Typography fontWeight={'600'} color={palette.white} fontSize={'0.75rem'}>
                    Productos y servicios de la caja
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
          <ClientCard headers={headers} isGoBack goBackMethod={() => setSelectedProduct('')}>
            <TableRow
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: 0,
                },
              }}>
              <TableCell component='th' scope='row'>
                <Typography variant='button' sx={styles.rowText}>
                  {selectedProduct?.name}{' '}
                </Typography>
              </TableCell>
              <TableCell component='th' scope='row'>
                <Typography variant='button' sx={styles.rowText}>
                  {selectedProduct?.classification}{' '}
                </Typography>
              </TableCell>
              <TableCell component='th' scope='row'>
                <Typography variant='button' sx={styles.rowText}>
                  {selectedProduct?.availability}
                </Typography>
              </TableCell>
              <TableCell component='th' scope='row'>
                <Typography variant='button' sx={styles.rowText}>
                  {selectedProduct?.location}
                </Typography>
              </TableCell>
              <TableCell component='th' scope='row'>
                <Typography variant='button' sx={styles.rowText}>
                  {selectedProduct?.phone}
                </Typography>
              </TableCell>
              <TableCell component='th' scope='row'>
                <Typography variant='button' sx={styles.rowText}>
                  {selectedProduct?.association}
                </Typography>
              </TableCell>
              <TableCell component='th' scope='row'>
                <Typography variant='button' sx={styles.rowText}>
                  {selectedProduct?.email}
                </Typography>
              </TableCell>
              <TableCell component='th' scope='row'>
                <Typography variant='button' sx={styles.rowText}>
                  {selectedProduct?.price}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  paddingTop: '1rem',
                  paddingBottom: '1rem',
                  borderTop: 0,
                },
              }}>
              <TableCell component='th' scope='row'>
                <Box display={'flex'} flexDirection={'column'}>
                  <Typography fontSize={'0.75rem'} fontWeight='700'>
                    Imagenes adjuntadas
                  </Typography>
                  {selectedProduct?.product_image?.[0] && (
                    <Box display={'flex'}>
                      <Typography margin={'0.5rem 0'} fontSize={'0.75rem'} sx={styles.fileText}>
                        {selectedProduct?.product_image?.[0]?.url}
                      </Typography>
                      <IconButton onClick={() => handleShowImage(selectedProduct?.product_image?.[0]?.url)}>
                        <Icon icon='ic:baseline-remove-red-eye' width={18} color={palette.black} />
                      </IconButton>
                    </Box>
                  )}
                  {selectedProduct?.product_image?.[1] && (
                    <Box display={'flex'}>
                      <Typography margin={'0.5rem 0'} fontSize={'0.75rem'} sx={styles.fileText}>
                        {selectedProduct?.product_image?.[1]?.url}
                      </Typography>
                      <IconButton onClick={() => handleShowImage(selectedProduct?.product_image?.[1]?.url)}>
                        <Icon icon='ic:baseline-remove-red-eye' width={18} color={palette.black} />
                      </IconButton>
                    </Box>
                  )}
                  {selectedProduct?.product_image?.[2] && (
                    <Box display={'flex'}>
                      <Typography margin={'0.5rem 0'} fontSize={'0.75rem'} sx={styles.fileText}>
                        {selectedProduct?.product_image?.[2]?.url}
                      </Typography>
                      <IconButton onClick={() => handleShowImage(selectedProduct?.product_image?.[2]?.url)}>
                        <Icon icon='ic:baseline-remove-red-eye' width={18} color={palette.black} />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              </TableCell>
            </TableRow>
          </ClientCard>
          <Box margin={'3rem 0 0 1%'} width={'49%'}>
            <TextField
              fullWidth
              label='Descripción y características'
              {...register('description')}
              disabled={true}
              control={control}
              multiline
              rows={4}
              ref={inputRef.current}
              placeholder={selectedProduct?.description}
            />
          </Box>
          {selectedProduct?.productStatus == StatusEnum?.WAITING && showButton && (
            <Box display={'flex'} justifyContent={'flex-end'} width='100%' paddingBottom='1rem' alignContent={'center'}>
              {/* {permissions.includes(PermissionEnum.RejectedLegal) && ( */}
              <Box width={'10.125rem'} marginRight='1rem'>
                <Button
                  onClick={() => setReviewStatus('Rejected')}
                  sx={{ background: palette?.error, width: '100%' }}
                  variant={'contained'}>
                  <Box display={'flex'} justifyContent={'center'} marginRight={'0.5rem'}>
                    <Icon icon='fa6-solid:circle-xmark' width={18} color={palette.white} />
                  </Box>
                  Rechazado
                </Button>
              </Box>
              {/* )} */}
              {/* {permissions.includes(PermissionEnum.ApproveLegal) && ( */}
              <Box width={'10.125rem'} marginRight='1%'>
                <Button
                  onClick={() => setReviewStatus('Approved')}
                  sx={{ background: palette?.success, width: '100%' }}
                  variant={'contained'}>
                  <Box display={'flex'} justifyContent={'center'} marginRight={'0.5rem'}>
                    <Icon icon='fa6-solid:circle-check' width={18} color={palette.white} />
                  </Box>
                  Aprobado
                </Button>
              </Box>
              {/* )} */}
            </Box>
          )}
        </Box>
      )}
      {status && currentImage && (
        <MuiModal
          open={status}
          width={'40rem'}
          height='40rem'
          onCancel={() => {
            switchStatus();
            dispatch(resetImage());
          }}>
          <Box
            component={'img'}
            src={currentImage}
            sx={{ backgroundImage: 'cover' }}
            width={'35rem'}
            height={'35rem'}
            alt='product_image'
          />
        </MuiModal>
      )}
    </Box>
  );
};

export const EmptyState = ({ loading }: any) => {
  return (
    <Box width={'100%'} height={'70%'} flexDirection={'column'} display={'flex'} justifyContent={'center'} flex={1}>
      <Box>
        {loading ? (
          <CircularProgress variant='indeterminate' sx={{ zIndex: 9999, color: palette?.dark }} />
        ) : (
          <>
            <Icon icon={'material-symbols:view-list-rounded'} width={52} color={palette?.dark} />
            <Typography color={palette?.dark} variant='subtitle1'>
              No existen productos o servicios
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
};
