import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  tableCellClasses,
  InputAdornment,
} from '@mui/material';

import { palette } from 'theme/contants';
import { styles } from '../../styles';
import { DownloadButton, Pagination, TextField } from 'components';
import { searchReport, setNotification, getMonthlyDetail, searchReportDetail } from 'store/actions';

import { useEffect, useRef, useState } from 'react';
import { clientsSelector, authSelector } from 'store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Icon } from '@iconify/react';
import { UsePagination } from 'hooks';
import { PermissionEnum } from 'utils/constants';

export const MonthlyData = ({ clientId }: any) => {
  const dispatch = useDispatch();
  const clientData = useSelector(clientsSelector);
  const {
    user: {
      role: { permissions },
    },
  } = useSelector(authSelector);
  const inputRef = useRef(null);
  const [search, setSearch] = useState('');

  const totalPages = clientData?.monthlyDetail?.meta?.pageCount || 1;
  const { nextPage, previousPage, page, customPage } = UsePagination(clientData?.monthlyDetail?.meta?.page, totalPages);

  const sendNotificacion = (status: boolean) => {
    dispatch(setNotification({ clientId, status }));
  };

  useEffect(() => {
    dispatch(getMonthlyDetail(clientId, page));
  }, [page]);

  const handleSearch = async ({ target }: any) => {
    const { value } = target;
    setSearch(value);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(searchReportDetail(search, clientId, customPage));
    }, 600);

    return () => clearTimeout(timeout);
  }, [search]);

  const {
    register,
    formState: { errors },
    control,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      search: '',
    },
  });

  return (
    <Box paddingBottom={'1rem'} width={'100%'} position={'relative'}>
      <Box
        width='100%'
        height={'calc(75vh - 5rem)'}
        position={'relative'}
        display='flex'
        justifyContent='flex-start'
        flexDirection='column'
        alignItems='center'>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          width={'98%'}
          margin={'1rem 1% 1rem 1%'}>
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <Typography fontSize={16} fontWeight={'600'} marginRight={'1rem'} color={palette?.dark}>
              Registros
            </Typography>
            <TextField
              label='Buscar'
              {...register('search', {
                onChange: handleSearch,
              })}
              placeholder='Mes de registro'
              type='text'
              name='search'
              control={control}
              id='search'
              InputProps={{
                style: { width: '28rem' },
                endAdornment: (
                  <InputAdornment position='end'>
                    <Icon icon='heroicons:magnifying-glass-solid' width={18} color={palette.black} />
                  </InputAdornment>
                ),
              }}
              ref={inputRef.current}
            />
          </Box>
          {permissions.includes(PermissionEnum.SendNotify) && !clientData.monthlyDetail.isUpdated && (
            <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
              <Button onClick={() => sendNotificacion(true)} variant='contained'>
                Enviar notificación
              </Button>
            </Box>
          )}
        </Box>
        <Box
          display={'flex'}
          height={'80%'}
          width={'98%'}
          sx={{ overflowY: 'scroll' }}
          margin={'0 1%'}
          position={'relative'}
          flexDirection={'column'}>
          <Table sx={{ bgcolor: palette?.white, borderRadius: '1.125rem' }} aria-label='simple table' stickyHeader>
            <TableHead
              sx={{
                width: '52.5rem',
                [`& .${tableCellClasses.root}`]: {
                  padding: '1rem',
                },
                top: 0,
                zIndex: 1,
                position: 'sticky',
              }}>
              <TableRow
                sx={{
                  [`& .${tableCellClasses.root}`]: {
                    paddingTop: '0.6rem',
                    paddingBottom: '0.6rem',
                    border: 'none',
                    bgcolor: palette.dark,
                    borderRadius: '0.438rem',
                  },
                }}>
                <TableCell align={'left'} width={'100%'} colSpan={12}>
                  <Typography fontWeight={'600'} color={palette.white} fontSize={'0.75rem'}>
                    Histórico de caja
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow
                sx={{
                  [`& .${tableCellClasses.root}`]: {
                    paddingTop: '1rem',
                    paddingBottom: '1rem',
                    borderBottom: `1px solid ${palette.gray}`,
                  },
                }}>
                <TableCell width={'70%'}>
                  <Typography fontSize={'0.75rem'} fontWeight='700'>
                    Mes
                  </Typography>
                </TableCell>
                <TableCell width={'25%'}>
                  <Typography fontSize={'0.75rem'} fontWeight='700'>
                    Descargar
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {clientData?.monthlyDetail?.data?.length > 0 ? (
                <>
                  {clientData?.monthlyDetail?.data.map((row: any, index: number) => (
                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell component='th' scope='row'>
                        <Typography fontSize={'0.75rem'} sx={styles.rowText}>
                          {row.name}{' '}
                        </Typography>
                      </TableCell>

                      <TableCell component='th' scope='row'>
                        <Box display={'flex'} marginLeft={'1rem'} justifyContent={'flex-start'} alignItems={'center'}>
                          <DownloadButton fileName={row?.filename} />
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              ) : (
                <TableRow
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}>
                  <TableCell align='center' colSpan={12}>
                    <Icon icon={'material-symbols:view-list-rounded'} width={52} color={palette?.dark} />
                    <Typography color={palette?.dark} variant='subtitle1'>
                      No hay historicos registrados
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
        <Box width={'98%'} position={'absolute'} bottom={0} padding={'0 1%'}>
          <Pagination next={nextPage} previous={previousPage} page={page} totalPages={totalPages} actualQty={1} />
        </Box>
      </Box>
    </Box>
  );
};
