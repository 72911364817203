import { Box, Typography } from '@mui/material';
import { palette } from 'theme/contants';
import { Icon } from '@iconify/react';
import { PaginationType } from './types';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';

export const Pagination: FC<PaginationType> = ({
  page = 1,
  totalPages = 1,
  next = null,
  previous = null,
  actualQty = 0,
}: any) => {
  useEffect(() => {
    if (actualQty == 0 && page !== 1) {
      previous();
    }
  }, [actualQty]);

  return (
    <Box width={'100%'} display={'flex'} marginTop={'1rem'} justifyContent={'space-between'} alignItems={'center'}>
      <Typography fontSize={'1rem'}>
        {page}/{totalPages}
      </Typography>
      <Box
        display={'flex'}
        alignItems={'center'}
        sx={{ cursor: 'pointer', '&:first-child': { marginRight: '0.75rem' } }}>
        <Box>
          {page != 1 && (
            <Icon icon='bx-left-arrow-alt' width={24} color={palette?.black} onClick={page != 1 ? previous : null} />
          )}
        </Box>
        <Box>
          {page != totalPages && (
            <Icon
              icon='bx:right-arrow-alt'
              width={24}
              color={palette?.black}
              onClick={page != totalPages ? next : null}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
