export enum StatusEnum {
  FINISHED = 'Finish',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  WAITING = 'Waiting',
}

export enum PermissionEnum {
  ListUsers = 'ListUsers',
  CreateUser = 'CreateUser',
  EditUser = 'EditUser',
  DeleteUser = 'DeleteUser',
  ListRoles = 'ListRoles',
  CreateRole = 'CreateRole',
  EditRole = 'EditRole',
  DeleteRole = 'DeleteRole',
  ApproveLegal = 'ApproveLegal',
  ApproveFinantial = 'ApproveFinantial',
  RejectedLegal = 'RejectedLegal',
  RejectedFinantial = 'RejectedFinantial',
  SendNotify = 'SendNotify',
}
