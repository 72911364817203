import { useRef, useState, useEffect } from 'react';
import { MuiModal, TextField, Pagination } from 'components';
import { Box, Button, CircularProgress, Grid, Switch, Typography } from '@mui/material';
import { styles } from './styles';
import { schema } from './validates';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { permissionsSelector, rolesSelector } from 'store/selectors';
import { useMediaQuery, Theme } from '@mui/material';
import { getPermissions, updateRole, sendRole } from 'store/actions';
import { palette } from 'theme/contants';
import { UsePagination } from 'hooks';

export const AddRolesModal = ({ open, onClose, type, rolData, rolePage }: any) => {
  const inputRef = useRef(null);
  const roles = useSelector(rolesSelector);
  const { rolesData } = roles;
  const permissions = useSelector(permissionsSelector);
  const {
    permissionsData: { data, meta },
  } = permissions;
  const totalPages = meta?.pageCount || 1;

  const { nextPage, previousPage, page } = UsePagination(meta?.page, totalPages, getPermissions);

  const [rolesList, setRolesList]: any = useState([rolesData]);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const [checked, setChecked] = useState(false);
  const setCheckedValue = () => setChecked((checked) => !checked);

  const {
    handleSubmit,
    register,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: rolData?.name ? rolData?.name : '',
      description: rolData?.description ? rolData?.description : '',
      permissions: rolData?.permissions ? rolData?.permissions.map((item: any) => item?.name) : [],
    },
    resolver: yupResolver(schema),
  });

  const formValues = getValues();

  const handleCheckPermissions = (value: any) => {
    const rolesCopy: any = [...formValues?.permissions];
    const index = rolesCopy.findIndex((item: string) => item == value);

    if (index === -1) {
      rolesCopy.push(value);
      setValue('permissions', rolesCopy);
      return;
    }
    rolesCopy.splice(index, 1);
    setValue('permissions', rolesCopy);
  };

  const onSubmit = (data: any) => {
    if (type == 'edit') return dispatch(updateRole(rolData?.id, data, onClose, rolePage));
    dispatch(sendRole(data, onClose, rolePage));
  };

  useEffect(() => {
    dispatch(getPermissions(1));
  }, []);

  useEffect(() => {
    setRolesList(rolesData);
  }, [rolesList]);

  return (
    <MuiModal
      width={'48.313rem'}
      height={'43rem'}
      title={type == 'create' ? 'Crear rol nuevo' : 'Editar rol '}
      titleAlign={isMobile ? 'center' : 'left'}
      open={open}
      marginTop={isMobile ? '2rem' : '4rem'}
      onCancel={onClose}>
      <Box sx={styles.modalBody}>
        <Grid
          marginTop={!isMobile ? '2rem' : '0'}
          display={'flex'}
          justifyContent={'space-between'}
          container
          spacing={0}>
          <Box width={'100%'} marginTop={'1rem'} display={'flex'} flexDirection={'column'}>
            <Box display={'flex'} sx={styles.inputContainer} justifyContent={'space-between'} alignContent={'center'}>
              <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                <TextField
                  fullWidth
                  label='Nombre del rol'
                  {...register('name')}
                  placeholder='Texto'
                  control={control}
                  helperText={errors?.name?.message ? errors?.name.message : ''}
                  error={!!errors?.name}
                  ref={inputRef.current}
                />
              </Grid>
            </Box>
          </Box>
          <Box width={'100%'} marginTop={'2rem'} display={'flex'} flexDirection={'column'}>
            <Box display={'flex'} sx={styles.inputContainer} justifyContent={'space-between'} alignContent={'center'}>
              <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label='Descripción del rol'
                  {...register('description')}
                  placeholder='Texto'
                  helperText={errors?.description?.message ? errors?.description.message : ''}
                  control={control}
                  error={!!errors?.description}
                  ref={inputRef.current}
                />
              </Grid>
            </Box>
          </Box>
          <Box width={'100%'} marginTop={'2rem'} display={'flex'} flexDirection={'column'}>
            <Box display={'flex'} sx={styles.inputContainer} justifyContent={'space-between'} alignContent={'center'}>
              <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                <Typography fontSize={'1rem'} marginBottom={'1rem'} fontWeight={'600'}>
                  Permisos
                </Typography>
                {permissions?.permissionsLoading ? (
                  <Box display={'flex'} justifyContent={'center'} width={'100%'} alignItems={'center'}>
                    <CircularProgress variant='indeterminate' sx={{ zIndex: 9999, color: palette?.dark }} />
                  </Box>
                ) : (
                  <>
                    <Box display={'flex'} justifyContent={'space-between'} flexDirection={'column'} width={'100%'}>
                      {data?.map((elem: any, index: number) => (
                        <Box key={index} width={'100%'} display={'flex'} justifyContent={'space-between'}>
                          <Typography fontSize={'1rem'} fontWeight={'600'}>
                            {elem?.nameToShow}
                          </Typography>
                          <Switch
                            sx={{
                              '& .Mui-checked.Mui-checked + .MuiSwitch-track': {
                                background: palette?.dark,
                              },
                            }}
                            id={elem?.name}
                            onChange={() => {
                              handleCheckPermissions(elem?.name);
                              setCheckedValue();
                            }}
                            checked={formValues?.permissions?.includes(elem?.name)}
                          />
                        </Box>
                      ))}
                    </Box>

                    <Pagination next={nextPage} previous={previousPage} page={page} totalPages={totalPages} actualQty={1} />
                  </>
                )}
              </Grid>
            </Box>
          </Box>
        </Grid>

        <Box display={'flex'} width={'100%'} sx={styles.buttonContainer} justifyContent={'space-between'}>
          <Button onClick={() => onClose()} variant='outlined' type='submit' sx={styles.button}>
            Cancelar
          </Button>

          <Button onClick={handleSubmit(onSubmit)} type='submit' sx={styles.button} variant='contained'>
            {type == 'create' ? 'Agregar' : 'Guardar'}
          </Button>
        </Box>
      </Box>
    </MuiModal>
  );
};
