import { Routes, Route } from 'react-router-dom';
import './App.css';

// Pages
import { Login } from './pages/Login';
import { Dashboard } from './pages/Dashboard/Home';
// import { MonthlyReport } from './pages/Dashboard/MonthlyReport';
import { PreRegister } from 'pages/Pre-Register';
import { Register } from 'pages/Register';
import { LegalRegistration } from './pages/Register/components/Legal';
import { FinancialRegistration } from './pages/Register/components/Financial';
import { ForgotPassword } from 'pages/ForgotPassword';
import { Toast, DashboardLayout, ProtectedRoute } from 'components';
import { Producst } from 'pages/Dashboard/Products';
import { AdminLogin } from 'pages/Admin/AdminLogin';
import { ClientsList } from 'pages/Admin/ClientsList';
import { ClientDetail } from 'pages/Admin/ClientDetail';
import { UsersList } from 'pages/Admin/Users';
import { RolesList } from 'pages/Admin/Roles';
import { Historical } from 'pages/Dashboard/Historical';
import { RestorePassword } from 'pages/RestorePassword';
import { AdminHome } from 'pages/Admin/AdminHome';
import { FinancialHistorical } from 'pages/Admin/FinancialHistorical';

const App = () => {
  return (
    <div className='App'>
      <Toast />
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='pre-register' element={<PreRegister />} />
        <Route path='admin-login' element={<AdminLogin />} />
        <Route path='restore-password' element={<RestorePassword />} />
        <Route path='forgot-password' element={<ForgotPassword />} />
        <Route element={<ProtectedRoute />}>
          <Route path='register' element={<Register />} />
          <Route path='legal-register' element={<LegalRegistration />} />
          <Route path='financial-register' element={<FinancialRegistration />} />
          <Route element={<DashboardLayout />}>
            <Route path='dashboard' element={<Dashboard />} />
            {/* <Route path='monthly-report' element={<MonthlyReport />} /> */}
            <Route path='products' element={<Producst />} />
            <Route path='clients-list' element={<ClientsList />} />
            <Route path='client-detail' element={<ClientDetail />} />
            <Route path='users' element={<UsersList />} />
            <Route path='roles' element={<RolesList />} />
            <Route path='historical' element={<Historical />} />
            <Route path='admin-dashboard' element={<AdminHome />} />
            <Route path='financial-historical' element={<FinancialHistorical />} />
          </Route>
        </Route>
      </Routes>
    </div>
  );
};

export default App;
