import { put, delay } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import { PermissionEnum } from './constants';

export const actionObject = (
  type: string,
  payload?: any | null,
  callback?: ({ ok, message, value, data }: any) => void,
): AnyAction => ({
  type,
  payload,
  callback,
});

const sleep = (time: number): Promise<any> => new Promise((resolve) => setTimeout(resolve, time));

const supportedDniFiletypes = ['image/png', 'image/jpg', 'image/jpeg'];

export function* showDialog(message = '', type = 'success', toast = 'SHOW_TOAST') {
  yield put(
    actionObject(toast, {
      show: true,
      message,
      type,
    }),
  );

  yield delay(3000);

  yield put(
    actionObject(toast, {
      show: false,
    }),
  );
}

const parseString = (number: number): string => {
  const newString = number.toString().replaceAll('.', ',');
  return newString;
};

const plainNumber = (amount: number) => {
  const numberString = amount.toString();
  let newNumber;
  newNumber = numberString.replaceAll('.', '');
  return parseAmount(newNumber);
};

const plainNumberString = (data: any) => {
  const { id, ...rest } = data;
  for (const key in rest) {
    data[key] = parseToPlainNumber(data[key]).toString();
  }

  return data;
};

const handleAmountFormat = (amount: any) => {
  return typeof amount === 'number' ? parseString(amount) : parseAmount(amount);
};

const parseToPlainNumber = (number: string) => {
  let localNumber;
  if (number.includes('.')) {
    localNumber = number.replaceAll('.', '');
    if (localNumber.includes(',')) localNumber = localNumber.replace(',', '.');
    const parsed = parseFloat(localNumber);
    localNumber = parsed;
    return parsed;
  }
  if (number.includes(',')) {
    localNumber = number.replace(',', '.');
    const parsed = parseFloat(localNumber);
    localNumber = parsed;
    return parsed;
  }

  return parseFloat(number);
};

const parseAmount = (amount: any = ''): string => {
  const parsedAmount = parseFloat(amount).toLocaleString('de-DE', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return parsedAmount;
};

const parseTotalAmount = (values: any, updateState: any) => {
  const keys: Array<any> = Object.entries(values);
  const total = keys.reduce((accumulator: number, value: any) => {
    let newValue = value[1].includes('.') ? value[1].replaceAll('.', '') : value[1];
    if (newValue.includes(',')) newValue = newValue.replaceAll(',', '.');
    return accumulator + parseFloat(newValue || '0');
  }, 0);

  updateState(total);
};

const convertStringToNumber = (data: any) => {
  const { id, completed, ...localData } = data;
  for (const key in localData) {
    const toNumber = parseFloat(localData[key]);
    localData[key] = toNumber;
  }

  return localData;
};

const filters = [
  { name: 'Ingresos', value: '400.00.00.00', icon: 'fa6-solid:money-bill-trend-up', rotate: false },
  { name: 'Egresos', value: '500.00.00.00', icon: 'fa6-solid:money-bill-trend-up', rotate: true },
  { name: 'Activos', value: '100.00.00.00', icon: 'fa6-solid:coins', rotate: false },
  { name: 'Pasivos', value: '200.00.00.00', icon: 'fa6-solid:money-bill-1-wave', rotate: false },
  { name: 'Patrimonio', value: '300.00.00.00', icon: 'fa6-solid:landmark', rotate: false },
];

const menuOptions = [
  {
    label: 'Principal',
    icon: 'fa6-solid:table-columns',
    route: '/dashboard',
    type: 'saving',
  },

  // {
  //   label: 'Declaración Mensual',
  //   icon: 'fa6-solid:money-bill-trend-up',
  //   route: '/monthly-report',
  //   type: 'saving',
  // },
  {
    label: 'Histórico de registro',
    icon: 'teenyicons:folder-solid',
    route: '/historical',
    type: 'saving',
  },
  {
    label: 'Productos y servicios',
    icon: 'fa6-solid:bars-progress',
    route: '/products',
    type: 'saving',
  },
  {
    label: 'Principal',
    icon: 'fa6-solid:table-columns',
    route: '/admin-dashboard',
    type: 'admin',
  },

  {
    label: 'Listado de cajas',
    icon: 'fa6-solid:folder',
    route: '/clients-list',
    type: 'admin',
  },
  {
    label: 'Estados financieros',
    icon: 'fa6-solid:briefcase',
    route: '/financial-historical',
    type: 'admin',
  },
  {
    label: 'Usuarios',
    icon: 'fa6-solid:users',
    route: '/users',
    type: 'admin',
    permission: PermissionEnum.ListUsers,
  },
  {
    label: 'Roles',
    icon: 'material-symbols:view-list-rounded',
    route: '/roles',
    type: 'admin',
    permission: PermissionEnum.ListRoles,
  },
];

const sectors = [
  { name: 'Publico', id: 'Publico' },
  { name: 'Privado', id: 'Privado' },
];

const savingStatus = [
  { name: 'Vigentes', id: 'Vigente' },
  { name: 'No Vigentes', id: 'No Vigente' },
  { name: 'En Proceso', id: 'En proceso de vigencia' },
];

const verifyStatus = [
  { name: 'Verificadas', id: 'Verificadas', value: true },
  { name: 'No Verificadas', id: 'No Verificadas', value: false },
];

const protocolStatus = [
  { name: 'Protocolizadas', id: 'Protocolizado' },
  { name: 'En Proceso', id: 'en proceso de protocolizacion' },
];

const months = [
  { name: 'Enero', value: '01', id: 'Enero' },
  { name: 'Febrero', value: '02', id: 'Febrero' },
  { name: 'Marzo', value: '03', id: 'Marzo' },
  { name: 'Abril', value: '04', id: 'Abril' },
  { name: 'Mayo', value: '05', id: 'Mayo' },
  { name: 'Junio', value: '06', id: 'Junio' },
  { name: 'Julio', value: '07', id: 'Julio' },
  { name: 'Agosto', value: '08', id: 'Agosto' },
  { name: 'Septiembre', value: '09', id: 'Septiembre' },
  { name: 'Octubre', value: '10', id: 'Octubre' },
  { name: 'Noviembre', value: '11', id: 'Noviembre' },
  { name: 'Diciembre', value: '12', id: 'Diciembre' },
];

const savingsSize = [
  { name: 'Pequeña', id: 'Pequeña' },
  { name: 'Mediana', id: 'Mediana' },
  { name: 'Grande', id: 'Grande' },
];

const paginate = (array: Array<any>, pageSize: number, pageNumber: number) => {
  return array?.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
};

const getYearsInRange = (currentDate: any, startingYear: any) => {
  const currentYear = currentDate.getFullYear();
  const startYear = startingYear;
  const years = [];

  for (let year = currentYear; year >= startYear; year--) {
    years.push({ name: year, id: year });
  }

  return years;
};

const hexColors = ['#000C5E', '#3C75BA', '#EEA71E'];

export {
  supportedDniFiletypes,
  parseAmount,
  parseTotalAmount,
  convertStringToNumber,
  filters,
  menuOptions,
  paginate,
  sleep,
  parseToPlainNumber,
  parseString,
  plainNumber,
  plainNumberString,
  handleAmountFormat,
  sectors,
  savingStatus,
  protocolStatus,
  hexColors,
  verifyStatus,
  months,
  savingsSize,
  getYearsInRange,
};
