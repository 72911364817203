import { GET_URL_S3_ASYNC, S3_LOADING } from './../intermitence/action-types';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { authSelector } from 'store/selectors';
import { actionObject, FetchService, showDialog } from 'utils';
import {
  GET_HISTORICAL,
  GET_SEGMENT,
  GET_SEGMENT_ASYNC,
  SAVE_MONTHLY_UPLOAD,
  GET_HISTORICAL_ASYNC,
  DOWNLOAD_FILE,
  LOADING,
  GET_PREVIEW,
  CLEAR_SEGMENT,
  CLEAR_SEGMENT_ASYNC,
  SEARCH_REPORT,
  SEARCH_REPORT_ASYNC,
  SET_LOADING,
  GET_FINANCIAL_HISTORICAL_ASYNC,
  GET_FINANCIAL_HISTORICAL,
  SEARCH_FINANCIAL_HISTORICAL,
  EXPORT_FINANCIAL_HISTORICAL_ASYNC,
  EXPORT_FINANCIAL_HISTORICAL,
} from './action-types';

function* getSegmentAsync({ payload }: any) {
  try {
    const {
      user: { accessToken },
    } = yield select(authSelector);
    const { id, type } = payload;
    const params = id ? `?parentId=${id}` : '';
    const { data, statusCode, response } = yield call(
      FetchService,
      `/accounting-parameters${params}`,
      'GET',
      undefined,
      accessToken,
    );

    if (data && statusCode == 200) yield put(actionObject(GET_SEGMENT_ASYNC, { data, type }));
    if (!data) yield call(showDialog, response?.message, 'error');
  } catch (error) {
    yield call(showDialog, 'Ha ocurrido un error', 'error');
  }
}

function* saveMonthlyUploadAsync({ payload }: any) {
  try {
    const {
      user: { accessToken },
    } = yield select(authSelector);
    const { data: monthlyData } = payload;

    const body = monthlyData.map((val: any) => ({
      amount: val.amount.toString().split('.').join(''),
      account: val.id,
    }));

    const newBody = {
      accounts: body,
      month: payload.params.month,
      year: payload.params.year,
    };

    const { data, response } = yield call(FetchService, `/monthly-upload?`, 'POST', newBody, accessToken);

    if (data) yield call(showDialog, 'Guardado exitoso', 'success');

    if (!data) yield call(showDialog, response?.message, 'error');
  } catch (error) {
    yield call(showDialog, 'Ha ocurrido un error', 'error');
  }
}

function* getHistoricalAsync({ payload }: any) {
  try {
    yield put(actionObject(LOADING, true));
    const {
      user: { accessToken },
    } = yield select(authSelector);

    const { data, statusCode, response } = yield call(
      FetchService,
      `/monthly-upload/me?page=${payload}`,
      'GET',
      null,
      accessToken,
    );

    if (data && statusCode == 200) {
      yield put(actionObject(LOADING, false));
      yield put(actionObject(GET_HISTORICAL_ASYNC, { ...data.data, isUpdated: data.isUpdated }));
    }

    if (!data) {
      yield put(actionObject(LOADING, false));
      throw new Error(response?.message);
    }
  } catch (error) {
    yield put(actionObject(LOADING, false));
    yield call(showDialog, 'Ha ocurrido un error', 'error');
  }
}

function* downloadFileAsync({ payload }: any) {
  try {
    const {
      user: { accessToken },
    } = yield select(authSelector);
    const { filename } = payload;

    const { data, statusCode, response } = yield call(
      FetchService,
      `/storage/download/${filename}`,
      'GET',
      undefined,
      accessToken,
    );

    const blob = new Blob([data.data]);
    const url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
  } catch (error) {
    yield call(showDialog, 'Ha ocurrido un error', 'error');
  }
}

function* getPreviewAsync({ payload }: any) {
  try {
    const {
      user: { accessToken },
    } = yield select(authSelector);
    const { data: monthlyData } = payload;

    const body = monthlyData.map((val: any) => ({
      amount: val.amount.toString().split('.').join(''),
      account: val.id,
    }));

    const { data, statusCode, response } = yield call(
      FetchService,
      '/monthly-upload/preview',
      'POST',
      body,
      accessToken,
    );

    const { url } = yield call(FetchService, `/storage/image-url/${data}`, 'GET', null, accessToken);

    if (url) {
      yield put(actionObject(GET_URL_S3_ASYNC, url));
      yield put(actionObject(S3_LOADING, false));
      window.open(url);
    }

    if (!data) yield call(showDialog, response?.message, 'error');
  } catch (error) {
    yield call(showDialog, 'Ha ocurrido un error', 'error');
  }
}

function* clearSegmentsAsync() {
  try {
    yield put(actionObject(CLEAR_SEGMENT_ASYNC));
  } catch (error) {
    yield call(showDialog, 'Ha ocurrido un error', 'error');
  }
}

function* searchReportAsync({ payload }: any) {
  const { name, customPage } = payload;

  try {
    const {
      user: { accessToken },
    } = yield select(authSelector);
    const { data, statusCode, response } = yield call(
      FetchService,
      `/monthly-upload/me?page=1&criteria=${name}`,
      'GET',
      undefined,
      accessToken,
    );

    if (data && statusCode == 200) {
      customPage(1);
      yield put(actionObject(SEARCH_REPORT_ASYNC, { ...data.data, isUpdated: data.isUpdated }));
    }

    if (!data) {
      throw new Error(response?.message);
    }
  } catch (error) {
    yield call(showDialog, 'Ha ocurrido un error', 'error');
  }
}

function* getFinancialHistoricalAsync({ payload }: any) {
  try {
    yield put(actionObject(SET_LOADING, true));
    const {
      user: { accessToken },
    } = yield select(authSelector);
    const { data, statusCode } = yield call(
      FetchService,
      `/monthly-upload?page=${payload.page}` +
        (payload?.month ? `&month=${payload?.month}` : '') +
        (payload?.year ? `&year=${payload?.year}` : '') +
        (payload?.size ? `&size=${payload?.size}` : '') +
        (payload?.sector ? `&sector=${payload?.sector}` : ''),
      'GET',
      null,
      accessToken,
    );

    if (data && statusCode === 200) {
      yield put(actionObject(SET_LOADING, false));

      yield put(
        actionObject(GET_FINANCIAL_HISTORICAL_ASYNC, { financialHistorical: data, filterHistoricalParams: payload }),
      );
      return;
    }
  } catch (error) {
    yield put(actionObject(SET_LOADING, false));
    yield call(showDialog, 'Ha ocurrido un error', 'error');
  }
}

function* searchFinancialHistoricalAsync({ payload }: any) {
  const { name, filterParams } = payload;

  try {
    yield put(actionObject(SET_LOADING, true));
    const {
      user: { accessToken },
    } = yield select(authSelector);
    const { data, statusCode } = yield call(
      FetchService,
      `/monthly-upload?page=1` +
        (name ? `&criteria=${name}` : '') +
        (filterParams?.month ? `&month=${filterParams?.month}` : '') +
        (filterParams?.year ? `&year=${filterParams?.year}` : '') +
        (filterParams?.size ? `&size=${filterParams?.size}` : '') +
        (filterParams?.sector ? `&sector=${filterParams?.sector}` : ''),
      'GET',
      null,
      accessToken,
    );

    if (data && statusCode === 200) {
      yield put(actionObject(SET_LOADING, false));

      yield put(
        actionObject(GET_FINANCIAL_HISTORICAL_ASYNC, { financialHistorical: data, filterHistoricalParams: payload }),
      );
      return;
    }
  } catch (error) {
    yield put(actionObject(SET_LOADING, false));
    yield call(showDialog, 'Ha ocurrido un error', 'error');
  }
}

function* exportFinancialHistoricalAsync({ payload, callback }: any) {
  try {
    yield put(actionObject(SET_LOADING, true));
    const {
      user: { accessToken },
    } = yield select(authSelector);
    const { data, statusCode } = yield call(
      FetchService,
      `/monthly-upload/export?` +
        (payload?.search ? `&criteria=${payload?.search}` : '') +
        (payload?.month ? `&month=${payload?.month}` : '') +
        (payload?.year ? `&year=${payload?.year}` : '') +
        (payload?.size ? `&size=${payload?.size}` : '') +
        (payload?.sector ? `&sector=${payload?.sector}` : ''),
      'GET',
      null,
      accessToken,
    );

    if (data && statusCode === 200) {
      yield put(actionObject(SET_LOADING, false));

      yield put(actionObject(EXPORT_FINANCIAL_HISTORICAL_ASYNC, { filterHistoricalParams: payload }));

      callback &&
        callback({
          ok: true,
          data: data,
        });
      return;
    }
  } catch (error) {
    yield put(actionObject(SET_LOADING, false));
    yield call(showDialog, 'Ha ocurrido un error', 'error');
  }
}

export function* watchGetSegment() {
  yield takeLatest(GET_SEGMENT, getSegmentAsync);
}

export function* watchSaveMonthlyUpload() {
  yield takeLatest(SAVE_MONTHLY_UPLOAD, saveMonthlyUploadAsync);
}

export function* watchGetHistorical() {
  yield takeLatest(GET_HISTORICAL, getHistoricalAsync);
}

export function* watchDownloadFile() {
  yield takeLatest(DOWNLOAD_FILE, downloadFileAsync);
}

export function* watchGetPreview() {
  yield takeLatest(GET_PREVIEW, getPreviewAsync);
}
export function* watchClearSegments() {
  yield takeLatest(CLEAR_SEGMENT, clearSegmentsAsync);
}
export function* watchSearchReport() {
  yield takeLatest(SEARCH_REPORT, searchReportAsync);
}

export function* watchGetFinancialHistorical() {
  yield takeLatest(GET_FINANCIAL_HISTORICAL, getFinancialHistoricalAsync);
}

export function* watchSearchFinancialHistorical() {
  yield takeLatest(SEARCH_FINANCIAL_HISTORICAL, searchFinancialHistoricalAsync);
}

export function* watchExportFinancialHistorical() {
  yield takeLatest(EXPORT_FINANCIAL_HISTORICAL, exportFinancialHistoricalAsync);
}
