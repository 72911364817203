const registerSelector = (state: any) => state.register;
const intermitenceSelector = (state: any) => state.intermitence;
const authSelector = (state: any) => state.auth;
const productsSelector = (state: any) => state.products;
const usersSelector = (state: any) => state.users;
const rolesSelector = (state: any) => state.roles;
const clientsSelector = (state: any) => state.clients;
const permissionsSelector = (state: any) => state.permissions;
const monthlyReportSelector = (state: any) => state.monthlyReport;
const reportsSelector = (state: any) => state.reports;
export {
  registerSelector,
  intermitenceSelector,
  authSelector,
  productsSelector,
  usersSelector,
  rolesSelector,
  clientsSelector,
  permissionsSelector,
  monthlyReportSelector,
  reportsSelector,
};
