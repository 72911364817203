import {
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  tableCellClasses,
  IconButton,
} from '@mui/material';
import { FC } from 'react';
import { palette } from 'theme/contants';
import { Icon } from '@iconify/react';

export const ClientCard: FC<any> = ({ children, headers, isGoBack = false, goBackMethod }: any) => {
  return (
    <Table
      sx={{
        bgcolor: palette.white,
        borderRadius: '0.625rem',
        width: '98%',
        boxShadow: 2,
        marginLeft: '1%',
        marginRight: '1%',
      }}
      aria-label='simple table'>
      <TableHead>
        <TableRow
          sx={{
            [`& .${tableCellClasses.root}`]: {
              paddingTop: '1rem',
              paddingBottom: '1rem',
              borderBottom: `1px solid ${palette.black}`,
            },
          }}>
          {headers?.map((item: string, index: number | string) => (
            <TableCell width={`${100 / headers.length}%`} key={index}>
              <Typography fontSize={'0.75rem'} fontWeight='700'>
                {item}
              </Typography>
            </TableCell>
          ))}
          {isGoBack && (
            <TableCell width={'12.5%'}>
              <IconButton onClick={goBackMethod}>
                <Icon icon='ic:baseline-close' width={18} color={palette.black} />
              </IconButton>
            </TableCell>
          )}
        </TableRow>
      </TableHead>

      <TableBody>{children}</TableBody>
    </Table>
  );
};
