import {
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  IconButton,
  TableCell,
  tableCellClasses,
  Box,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import { FC } from 'react';
import { palette } from 'theme/contants';
import { styles } from './styles';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import { Pagination } from 'components/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { clientsSelector } from 'store/selectors';
import { parseAmount } from 'utils';
import { setClientDetailSection, setClientIsOnboarding } from 'store/actions';

export const ClientsTable: FC<any> = ({
  rowsData = [],
  title,
  headers,
  page,
  nextPage,
  previousPage,
  totalPages,
}: any) => {
  const navigation = useNavigate();
  const clients = useSelector(clientsSelector);
  const dispatch = useDispatch();

  return (
    <>
      <Box
        display={'flex'}
        height={'70%'}
        width={'98%'}
        sx={{ overflowY: 'scroll' }}
        margin={'0 1%'}
        position={'relative'}
        flexDirection={'column'}>
        <Table sx={{ bgcolor: palette.white, borderRadius: '1.125rem' }} aria-label='simple table' stickyHeader>
          <TableHead
            sx={{
              width: '52.5rem',
              [`& .${tableCellClasses.root}`]: {
                padding: '1rem',
              },
              position: 'sticky',
              top: 0,
              zIndex: 1,
            }}>
            <TableRow
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  paddingTop: '0.5rem',
                  paddingBottom: '0.5rem',
                  border: 'none',
                  bgcolor: palette.dark,
                  borderRadius: '0.438rem',
                },
              }}>
              <TableCell align={'left'} width={'100%'} colSpan={12}>
                <Typography fontWeight={'600'} color={palette.white} fontSize={'0.75rem'}>
                  {title}
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  paddingTop: '0.5rem',
                  paddingBottom: '0.5rem',
                  borderBottom: `1px solid ${palette.gray}`,
                },
              }}>
              <TableCell width={'50%'}>
                <Typography fontSize={'0.75rem'} fontWeight='700'>
                  {headers[0]}
                </Typography>
              </TableCell>
              <TableCell width={'10%'}>
                <Typography fontSize={'0.75rem'} fontWeight='700'>
                  {headers[1]}
                </Typography>
              </TableCell>
              <TableCell width={'10%'}>
                <Typography fontSize={'0.75rem'} fontWeight='700'>
                  {headers[2]}
                </Typography>
              </TableCell>
              <TableCell width={'10%'}>
                <Typography fontSize={'0.75rem'} fontWeight='700'>
                  {headers[3]}
                </Typography>
              </TableCell>
              <TableCell width={'15%'}>
                <Typography fontSize={'0.75rem'} fontWeight='700'>
                  {headers[4]}
                </Typography>
              </TableCell>
              <TableCell width={'5%'}>
                <Typography fontSize={'0.75rem'} fontWeight='700'>
                  {headers[5]}
                </Typography>
              </TableCell>
              <TableCell width={'5%'}>
                <Typography fontSize={'0.75rem'} fontWeight='700'>
                  {headers[6]}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rowsData.length > 0 ? (
              <>
                {rowsData?.map((row: any, index: number) => {
                  return (
                    <TableRow
                      key={index}
                      sx={{
                        zIndex: 0,
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}>
                      <TableCell component='th' scope='row'>
                        <Tooltip title={row?.name ?? ''} placement={'top'}>
                          <Typography sx={styles.rowText}>{row.name} </Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell component='th' scope='row'>
                        <Typography sx={styles.rowText}>{row.user} </Typography>
                      </TableCell>
                      <TableCell component='th' scope='row'>
                        <Typography sx={styles.rowText}>
                          {row.is_verified == false ? 'Sin verificar' : 'Verificado'}{' '}
                        </Typography>
                      </TableCell>
                      <TableCell component='th' scope='row'>
                        <Tooltip title={row.status ?? ''} placement={'top'}>
                          <Typography sx={styles.rowText}>{row.status}</Typography>
                        </Tooltip>
                      </TableCell>

                      <TableCell component='th' scope='row'>
                        <Tooltip
                          title={
                            row?.financial?.asset?.national_currency
                              ? parseAmount(row?.financial?.asset?.national_currency)
                              : ''
                          }
                          placement={'top'}>
                          <Typography sx={styles.rowText}>
                            {row?.financial?.asset?.national_currency
                              ? parseAmount(row?.financial?.asset?.national_currency)
                              : ''}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell component='th' scope='row'></TableCell>

                      <TableCell component='th' scope='row' sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <IconButton sx={{ margin: '0 0.5rem', position: 'relative' }} disabled>
                          {row?.financial?.financialStatus == 'Waiting' || row?.legal?.status == 'Waiting' ? (
                            <Box
                              position={'absolute'}
                              right={5}
                              top={8}
                              bgcolor={palette?.primary}
                              width={10}
                              borderRadius={'50%'}
                              height={10}>
                              {' '}
                            </Box>
                          ) : null}
                          <Icon icon='fa6-solid:circle-exclamation' width={17} color={palette.black} />
                        </IconButton>
                        <IconButton
                          sx={{ margin: '0 0.5rem' }}
                          onClick={() => {
                            localStorage.setItem('clientSelected', row?.id);
                            dispatch(setClientDetailSection(row?.is_onboarding ? 1 : 2));
                            dispatch(setClientIsOnboarding(row?.is_onboarding));
                            navigation('/client-detail', { state: { clientSelected: row } });
                          }}>
                          <Icon icon='heroicons:eye-solid' width={22} color={palette.black} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </>
            ) : (
              <TableRow
                sx={{
                  height: '33.594rem',

                  '&:last-child td, &:last-child th': { border: 0 },
                }}>
                <TableCell align='center' colSpan={12}>
                  {clients?.clientsLoading ? (
                    <CircularProgress variant='indeterminate' sx={{ zIndex: 9999, color: palette?.dark }} />
                  ) : (
                    <>
                      <Icon icon={'material-symbols:view-list-rounded'} width={52} color={palette?.dark} />
                      <Typography color={palette?.dark} variant='subtitle1'>
                        No hay cajas registradas
                      </Typography>
                    </>
                  )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Box>
      <Box width={'98%'} position={'absolute'} bottom={0} padding={'0 1%'}>
        <Pagination next={nextPage} previous={previousPage} page={page} totalPages={totalPages} />
      </Box>
    </>
  );
};
