import { Box, Typography, Fade } from '@mui/material';
import { styles } from '../../styles';
import { palette } from 'theme/contants';
import { MuiCard, Statistics } from 'components';
import { useEffect } from 'react';
import { getClientStatistics } from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { clientsSelector } from 'store/selectors';

export const Principal = ({ clientId }: any) => {
  const dispatch = useDispatch();
  const clientData = useSelector(clientsSelector);

  useEffect(() => {
    dispatch(getClientStatistics(clientId));
  }, []);

  return (
    <>
      <Fade in={true} timeout={3000}>
        <Box
          sx={styles.graphicsMain}
          display='flex'
          flexDirection='column'
          alignItems='center'
          height='100vh - 5rem'
          bgcolor={palette.light}>
          <Box
            marginTop={'1rem'}
            width='100%'
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            position='relative'>
            <Box width={'48%'} margin={'0 1%'}>
              <MuiCard width={'100%'} title='Análisis de liquidez'>
                <Box display={'flex'} flexDirection={'column'} width={'100%'}>
                  <Statistics
                    text='Disponibilidad Financiera:'
                    icon='fa-solid:chart-pie'
                    percent={clientData?.clientStatistics?.financialAvailability}
                  />
                  <Statistics
                    text='Liquidez Inmediata:'
                    icon='mdi:chart-line'
                    percent={clientData?.clientStatistics?.immediateLiquidity}
                  />
                  <Statistics
                    text='Liquidez General:'
                    icon='fa6-solid:chart-bar'
                    percent={clientData?.clientStatistics?.overallLiquidity}
                  />
                  <Typography marginTop={'1rem'} textAlign={'right'} variant='button'>
                    {clientData?.clientStatistics?.lastUploadDate}
                  </Typography>
                </Box>
              </MuiCard>
            </Box>

            <Box width={'48%'} margin={'0 1%'}>
              <MuiCard width={'100%'} title='Análisis de Endeudamiento'>
                <Box display={'flex'} flexDirection={'column'} width={'100%'}>
                  <Statistics
                    text='Del Capital con Relación a los préstamos'
                    icon='fa-solid:chart-pie'
                    percent={clientData?.clientStatistics?.capitalToLoans}
                  />
                  <Statistics
                    text='Del Patrimonio con Relación a los préstamos'
                    icon='mdi:chart-line'
                    percent={clientData?.clientStatistics?.equityToLoans}
                  />
                  <Statistics
                    text='Del Pasivo con Relación a los Haberes'
                    icon='fa6-solid:chart-bar'
                    percent={clientData?.clientStatistics?.liabilityToLoans}
                  />
                  <Typography marginTop={'1rem'} textAlign={'right'} variant='button'>
                    {clientData?.clientStatistics?.lastUploadDate}
                  </Typography>
                </Box>
              </MuiCard>
            </Box>
          </Box>

          <Box width={'100%'} marginTop={'1.5rem'}>
            <Box width={'48%'} margin={'0 1%'}>
              <MuiCard width={'100%'} height={'8rem'} title='Análisis de la Reserva'>
                <Box display={'flex'} justifyContent={'flex-start'} flexDirection={'column'} width={'100%'}>
                  <Statistics
                    text='Disponibilidad Financiera:'
                    icon='fa-solid:chart-pie'
                    percent={clientData?.clientStatistics?.reserveAnalysis}
                  />

                  <Typography marginTop={'1rem'} textAlign={'right'} variant='button'>
                    {clientData?.clientStatistics?.lastUploadDate}
                  </Typography>
                </Box>
              </MuiCard>
            </Box>
          </Box>
        </Box>
      </Fade>
    </>
  );
};
