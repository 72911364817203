import { call, put, takeLatest } from 'redux-saga/effects';
import { actionObject, FetchService } from '../../utils';
import {
  LOGIN,
  LOGIN_ASYNC,
  PRE_REGISTER,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_ASYNC,
  RESTORE_PASSWORD,
  RESTORE_PASSWORD_ASYNC,
  USER_LOGIN,
  USER_LOGIN_ASYNC,
} from './action-types';
import { showDialog } from '../../utils';
import { LOGIN_LOADING, PRE_REGISTER_LOADING } from 'store/intermitence/action-types';

function* loginUserAsync({ payload }: any) {
  const { body, navigate } = payload;

  try {
    yield put(actionObject(LOGIN_LOADING, true));
    const { data, statusCode, response } = yield call(FetchService, '/auth/login', 'POST', body);

    if (data && statusCode === 200) {
      yield put(actionObject(LOGIN_LOADING, false));
      yield put(actionObject(LOGIN_ASYNC, { user: data }));

      !data?.is_onboarding ? navigate('/register') : navigate('/dashboard');
      yield call(showDialog, 'Inicio de sesión exitoso', 'success');
    }

    if (!data) {
      yield put(actionObject(LOGIN_LOADING, false));
      yield call(showDialog, 'Ha ocurrido un error', 'error');
    }
  } catch (error) {
    yield put(actionObject(LOGIN_LOADING, false));
    yield call(showDialog, 'Ha ocurrido un error', 'error');
    return error;
  }
}

function* userLoginAsync({ payload }: any) {
  const { body, navigate } = payload;

  try {
    yield put(actionObject(LOGIN_LOADING, true));
    const { data, statusCode } = yield call(FetchService, '/user/login', 'POST', body);

    if (data && statusCode === 200) {
      yield put(actionObject(LOGIN_LOADING, false));
      yield put(actionObject(USER_LOGIN_ASYNC, { user: data }));
      navigate('/admin-dashboard');
      yield call(showDialog, 'Inicio de sesión exitoso', 'success');
    }

    if (!data) {
      yield put(actionObject(LOGIN_LOADING, false));
      yield call(showDialog, 'Ha ocurrido un error', 'error');
    }
  } catch (error) {
    yield put(actionObject(LOGIN_LOADING, false));
    yield call(showDialog, 'Ha ocurrido un error', 'error');
    return error;
  }
}

function* forgotPasswordAsync({ payload }: any) {
  const { body } = payload;

  try {
    yield put(actionObject(LOGIN_LOADING, true));
    const { data, statusCode } = yield call(FetchService, '/saving-bank/forgot-password', 'POST', body);

    if (data && statusCode === 200) {
      yield put(actionObject(LOGIN_LOADING, false));
      yield put(actionObject(FORGOT_PASSWORD_ASYNC, data));
      yield call(showDialog, 'Se envió un correo electrónico al email de la caja', 'success');
    }

    if (!data) {
      yield put(actionObject(LOGIN_LOADING, false));
      yield call(showDialog, 'Ha ocurrido un error', 'error');
    }
  } catch (error) {
    yield put(actionObject(LOGIN_LOADING, false));
    yield call(showDialog, 'Ha ocurrido un error', 'error');
    return error;
  }
}

function* restorePasswordAsync({ payload }: any) {
  const { body, navigate } = payload;

  try {
    yield put(actionObject(LOGIN_LOADING, true));
    const { data, statusCode, response } = yield call(FetchService, '/saving-bank/restore-password', 'POST', body);

    if (data && statusCode === 200) {
      yield put(actionObject(LOGIN_LOADING, false));
      yield put(actionObject(RESTORE_PASSWORD_ASYNC, data));
      navigate('/');
      yield call(showDialog, 'Cambio de contraseña éxitoso', 'success');
    }

    if (!data) {
      yield put(actionObject(LOGIN_LOADING, false));
      yield call(showDialog, 'Ha ocurrido un error', 'error');
    }
  } catch (error) {
    yield put(actionObject(LOGIN_LOADING, false));
    yield call(showDialog, 'Ha ocurrido un error', 'error');
    return error;
  }
}

function* preRegisterAsync({ payload }: any) {
  const { body, switchStatus, resetForm } = payload;
  try {
    yield put(actionObject(PRE_REGISTER_LOADING, true));
    const { data, statusCode, response } = yield call(FetchService, '/saving-bank/', 'PUT', body);

    if (data && statusCode == 200) {
      switchStatus();
      resetForm();
      yield put(actionObject(PRE_REGISTER_LOADING, false));
      yield call(showDialog, 'Pre registro exitoso', 'success');
    }

    if (!data) {
      yield put(actionObject(PRE_REGISTER_LOADING, false));
      if (response?.message) yield call(showDialog, response?.message, 'error');
      yield call(showDialog, 'Ha ocurrido un error', 'error');
    }
  } catch (error) {
    yield put(actionObject(PRE_REGISTER_LOADING, false));
    yield call(showDialog, 'Ha ocurrido un error', 'error');
    return error;
  }
}

export function* watchLoginUser() {
  yield takeLatest(LOGIN, loginUserAsync);
}

export function* watchPreRegister() {
  yield takeLatest(PRE_REGISTER, preRegisterAsync);
}

export function* watchForgotPassword() {
  yield takeLatest(FORGOT_PASSWORD, forgotPasswordAsync);
}

export function* watchRestorePassword() {
  yield takeLatest(RESTORE_PASSWORD, restorePasswordAsync);
}

export function* watchUserLogin() {
  yield takeLatest(USER_LOGIN, userLoginAsync);
}
