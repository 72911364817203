import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

const UsePagination = (initalPage: number = 1, totalPages: number = 1, action?: any, actionParams?: any) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(initalPage);
  const [searching, setSearching] = useState(false);

  const nextPage = () => {
    setSearching(false);
    if (page <= totalPages - 1) setPage((currentPage: number) => currentPage + 1);
  };
  const previousPage = () => {
    setSearching(false);
    if (page >= 2) setPage((currentPage: number) => currentPage - 1);
  };

  const customPage = (page: number) => {
    setPage(page);
    setSearching(true);
  };

  useEffect(() => {
    if (action && !searching && page > 0) dispatch(action(actionParams ? { page, ...actionParams } : page));
  }, [page, searching]);

  return { page, nextPage, previousPage, customPage };
};

export default UsePagination;
