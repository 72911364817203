import { Box, Typography } from '@mui/material';
import { styles } from './styles';
import { palette } from 'theme/contants';
import { Icon } from '@iconify/react';
import { LegalData, FinancialData, MonthlyData, ProductsData, Principal } from './components';
import { MuiChips } from 'components';
import { useEffect, useState } from 'react';
import { clientsSelector } from 'store/selectors';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getMonthlyDetail, resetDetailData } from 'store/actions';

export const ClientDetail = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [actualSection, setActualSection] = useState(1);
  const { monthlyDetail, clientIsOnboarding, clientSectionDetail } = useSelector(clientsSelector);
  const navigate = useNavigate();
  const clientSelected = localStorage.getItem('clientSelected');

  const sections = [
    { name: 'Principal de la caja', value: 1, haveIcon: false },
    { name: 'Datos legales de la caja', value: 2, haveIcon: false },
    { name: 'Datos financieros de la caja', value: 3, haveIcon: false },
    { name: 'Ingresos mensuales de la caja', value: 4, haveIcon: false },
    { name: 'Productos y servicios', value: 5, haveIcon: false },
  ];

  const returnFromStepComponent = (section: number) => {
    switch (section) {
      case 1:
        return <Principal clientId={clientSelected} />;
      case 2:
        return <LegalData clientId={clientSelected} />;
      case 3:
        return <FinancialData clientId={clientSelected} />;
      case 4:
        return <MonthlyData clientId={clientSelected} />;
      case 5:
        return <ProductsData clientId={clientSelected} />;
      default:
        return <LegalData />;
    }
  };

  useEffect(() => {
    return () => {
      dispatch(resetDetailData());
      dispatch(getMonthlyDetail(clientSelected, 1));
    };
  }, []);

  useEffect(() => {
    setActualSection(clientSectionDetail);
  }, [clientSectionDetail]);

  return (
    <Box sx={styles.main} display='flex' flexDirection='column' bgcolor={palette.white}>
      <Box display={'flex'} flexDirection={'column'} marginRight={'1%'} marginLeft={'1%'}>
        <Typography
          sx={styles?.title}
          textAlign={'left'}
          marginBottom={'1rem'}
          fontSize={'1rem'}
          color={palette?.dark}
          fontWeight={'600'}>
          {location?.state?.clientSelected?.name}
        </Typography>
        <Typography
          sx={styles?.title}
          textAlign={'left'}
          marginBottom={'1rem'}
          fontSize={'0.875rem'}
          color={palette?.dark}>
          {location?.state?.clientSelected?.user}
        </Typography>
      </Box>

      <Box
        width='100%'
        height={'100%'}
        marginTop={'3rem'}
        display='flex'
        justifyContent='center'
        flexDirection='column'
        alignItems='center'
        position='relative'>
        <Box
          marginBottom={'2rem'}
          width={'98%'}
          display={'flex'}
          marginRight={'1%'}
          justifyContent={'space-between'}
          alignItems={'center'}>
          <Box
            display={'flex'}
            sx={{ cursor: 'pointer' }}
            justifyContent={'flex-start'}
            alignItems={'flex-start'}
            onClick={() => {
              navigate('/clients-list');
              localStorage.removeItem('clientSelected');
            }}>
            <Icon icon='material-symbols:chevron-left' width={25} color={palette.black} />
            <Typography fontSize={'1rem'} fontWeight={'600'}>
              Volver a todas las cajas
            </Typography>
          </Box>
          <Box display={'flex'} justifyContent={'space-between'}>
            {sections?.map((item: any, index: number) => {
              if (!clientIsOnboarding && item.value === 1) return <></>;
              if (item.value === 4) {
                item.haveIcon = monthlyDetail?.isUpdated == false ? true : false;
              }

              return (
                <MuiChips
                  key={index}
                  icon={item?.haveIcon ? 'fa6-solid:circle-exclamation' : ''}
                  method={() => setActualSection(item?.value)}
                  isSelected={item?.value == actualSection ? true : false}
                  text={item?.name}
                />
              );
            })}
          </Box>
        </Box>
        <Box width={'100%'} height={'100%'}>
          {returnFromStepComponent(actualSection)}
        </Box>
      </Box>
    </Box>
  );
};
