import { call } from 'redux-saga/effects';
import axios from 'axios';

const HOST_URL = process.env.API_URL || 'https://apia.sudeca.gob.ve/api';

export function* FetchService(
  url: string,
  method: string = 'GET',
  params: any = undefined,
  auth: string | any = null,
  isImage: boolean = false,
): any {
  const objectRequest: any = {
    method,
    url: `${HOST_URL}${url}`,
    data: params,
    headers: {
      'Content-Type': !isImage ? 'application/json' : 'multipart/form-data',
      Accept: 'application/json',
    },
  };

  if (auth) objectRequest.headers.Authorization = `Bearer ${auth}`;

  const response = yield call(axios, objectRequest);
  const responseBody = response.data;
  return responseBody;
}
