export const SET_CLIENT_ID = 'SET_CLIENT_ID';
export const GET_CLIENT_STATISTICS = 'GET_CLIENT_STATISTICS';
export const GET_CLIENT_STATISTICS_ASYNC = 'GET_CLIENT_STATISTICS_ASYNC';
export const GET_CLIENT_LEGAL_DETAIL = 'GET_CLIENT_LEGAL_DETAIL';
export const GET_CLIENT_LEGAL_DETAIL_ASYNC = 'GET_CLIENT_LEGAL_DETAIL_ASYNC';
export const GET_CLIENT_FINANCIAL_DETAIL = 'GET_CLIENT_FINANCIAL_DETAIL';
export const GET_CLIENT_FINANCIAL_DETAIL_ASYNC = 'GET_CLIENT_FINANCIAL_DETAIL_ASYNC';
export const SET_FINANCIAL_STATUS = 'SET_FINANCIAL_STATUS';
export const SET_FINANCIAL_STATUS_ASYNC = 'SET_FINANCIAL_STATUS_ASYNC';
export const SET_LEGAL_STATUS = 'SET_LEGAL_STATUS';
export const SET_LEGAL_STATUS_ASYNC = 'SET_LEGAL_STATUS_ASYNC';
export const LOADING_CLIENTS_GET = 'LOADING_CLIENTS_GET';
export const GET_CLIENTS = 'GET_CLIENTS';
export const GET_CLIENTS_ASYNC = 'GET_CLIENTS_ASYNC';
export const SEARCH_CLIENTS = 'SEARCH_CLIENTS';
export const SEARCH_CLIENTS_ASYNC = 'SEARCH_CLIENTS_ASYNC';
export const RESET_DETAIL_DATA = 'RESET_DETAIL_DATA';
export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const SET_NOTIFICATION_ASYNC = 'SET_NOTIFICATION_ASYNC';
export const GET_CLIENT_MONTHLY_DETAIL = 'GET_CLIENT_MONTHLY_DETAIL';
export const GET_CLIENT_MONTHLY_DETAIL_ASYNC = 'GET_CLIENT_MONTHLY_DETAIL_ASYNC';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const SET_FILTERS = 'SET_FILTERS';
export const SEARCH_REPORT_DETAIL = 'SEARCH_REPORT_DETAIL';
export const SEARCH_REPORT_DETAIL_ASYNC = 'SEARCH_REPORT_DETAIL_ASYNC';
export const SET_IS_ONBOARDING = 'SET_IS_ONBOARDING';
export const SET_SHOW_DETAIL_SECTION = 'SET_SHOW_DETAIL_SECTION';
