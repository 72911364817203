import { call, put, takeLatest, select } from 'redux-saga/effects';
import { actionObject, FetchService } from '../../utils';
import {
  GET_CLIENTS,
  GET_CLIENTS_ASYNC,
  GET_CLIENT_FINANCIAL_DETAIL,
  GET_CLIENT_FINANCIAL_DETAIL_ASYNC,
  GET_CLIENT_LEGAL_DETAIL,
  GET_CLIENT_LEGAL_DETAIL_ASYNC,
  LOADING_CLIENTS_GET,
  SET_FINANCIAL_STATUS,
  SET_LEGAL_STATUS,
  GET_CLIENT_STATISTICS,
  GET_CLIENT_STATISTICS_ASYNC,
  SEARCH_CLIENTS_ASYNC,
  SEARCH_CLIENTS,
  SET_NOTIFICATION,
  GET_CLIENT_MONTHLY_DETAIL,
  GET_CLIENT_MONTHLY_DETAIL_ASYNC,
  SHOW_MESSAGE,
  SEARCH_REPORT_DETAIL_ASYNC,
  SEARCH_REPORT_DETAIL,
} from './action-types';
import { authSelector } from 'store/selectors';
import { showDialog } from 'utils';

function* getClientsAsync({ payload }: any) {
  const { currentStatus, protocolization, isVerified, page } = payload;

  try {
    yield put(actionObject(LOADING_CLIENTS_GET, true));
    const {
      user: { accessToken },
    } = yield select(authSelector);
    const { data, statusCode, response } = yield call(
      FetchService,
      `/saving-bank?page=${typeof payload == 'number' ? payload : page || 1}` +
        (currentStatus ? `&currentStatus=${currentStatus}` : '') +
        (protocolization ? `&protocolization=${protocolization}` : '') +
        (isVerified !== null && isVerified !== undefined ? `&isVerified=${isVerified}` : ''),
      'GET',
      null,
      accessToken,
    );

    if (data && statusCode === 200) {
      yield put(actionObject(GET_CLIENTS_ASYNC, data));
      yield put(actionObject(LOADING_CLIENTS_GET, false));
    }

    if (!data) {
      yield put(actionObject(LOADING_CLIENTS_GET, false));
      throw new Error(response?.message);
    }
  } catch (error) {
    yield put(actionObject(LOADING_CLIENTS_GET, false));
    throw new Error('Ha ocurrido un error');
  }
}

function* searchClientsAsync({ payload }: any) {
  const { name, customPage, currentStatus, protocolization, isVerified } = payload;

  try {
    const {
      user: { accessToken },
    } = yield select(authSelector);
    const { data, statusCode, response } = yield call(
      FetchService,
      `/saving-bank?page=1&criteria=${name}` +
        (currentStatus ? `&currentStatus=${currentStatus}` : '') +
        (protocolization ? `&protocolization=${protocolization}` : '') +
        (isVerified !== null && isVerified !== undefined ? `&isVerified=${isVerified}` : ''),
      'GET',
      null,
      accessToken,
    );

    if (data && statusCode == 200) {
      customPage(1);
      yield put(actionObject(SEARCH_CLIENTS_ASYNC, data));
    }

    if (!data) {
      throw new Error(response?.message);
    }
  } catch (error) {
    yield call(showDialog, 'Ha ocurrido un error', 'error');
  }
}

// const formattedDate = (dateString: Date) => {
//   const date = new Date(dateString);
//   return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
// };

const formattedDate = (dateString: Date) => {
  const date = new Date(dateString);
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Add leading zero for months
  const day = date.getDate().toString().padStart(2, '0'); // Add leading zero for days
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
};

function* getClientsLegalAsync({ payload }: any) {
  const { id } = payload;

  try {
    yield put(actionObject(LOADING_CLIENTS_GET, true));
    const {
      user: { accessToken },
    } = yield select(authSelector);

    const { data, statusCode, response } = yield call(FetchService, `/legal/detail/${id}`, 'GET', null, accessToken);

    const {
      entityData,
      protocolData,
      administrativeMembers,
      vigilanceMembers,
      legalStatus,
      explainAdmin,
      explainWatch,
    } = data;

    const { path_constitutive_act, path_swearing_certificate, statute } = protocolData;

    const legalDetail = {
      entityData,
      protocolData: protocolData
        ? {
            ...protocolData,
            elected_start_date: formattedDate(protocolData?.elected_start_date),
            elected_end_date: formattedDate(protocolData?.elected_end_date),
            date_swearing_certificate: formattedDate(protocolData?.date_swearing_certificate),
            date_constitutive_act: formattedDate(protocolData?.date_constitutive_act),
            date_proto_constitutive_act: formattedDate(protocolData?.date_proto_constitutive_act),
            date_statute: formattedDate(protocolData?.date_statute),
          }
        : undefined,

      administrativeMembers,
      vigilanceMembers,
      legalStatus,
      explainAdmin,
      explainWatch,
    };

    if (data && statusCode == 200) {
      yield put(actionObject(LOADING_CLIENTS_GET, false));
      yield put(actionObject(GET_CLIENT_LEGAL_DETAIL_ASYNC, legalDetail));
      yield put(actionObject(GET_CLIENT_MONTHLY_DETAIL, { id }));
    }

    if (!data) {
      yield put(actionObject(LOADING_CLIENTS_GET, false));
      return response?.message;
    }
  } catch (error) {
    yield put(actionObject(LOADING_CLIENTS_GET, false));
    yield put(
      actionObject(GET_CLIENT_LEGAL_DETAIL_ASYNC, {
        legalDetail: {
          // ...legalDetail,
          protocolData: {},
          administrativeMembers: {},
          vigilanceMembers: {},
          legalStatus: '',
          explainAdmin: '',
          explainWatch: '',
        },
      }),
    );

    yield call(showDialog, 'Ha ocurrido un error', 'error');
  }
}

function* getClientsFinancialAsync({ payload }: any) {
  const { id } = payload;

  try {
    yield put(actionObject(LOADING_CLIENTS_GET, true));
    const {
      user: { accessToken },
    } = yield select(authSelector);

    const { data, statusCode, response } = yield call(
      FetchService,
      `/financial/detail/${id}`,
      'GET',
      null,
      accessToken,
    );

    const financialDetail = {
      income: data?.income,
      expense: data?.expense,
      liability: data?.liability,
      asset: data?.asset,
      capital: data?.capital,
      financialStatus: data?.financialStatus,
    };

    if (data && statusCode == 200) {
      yield put(actionObject(LOADING_CLIENTS_GET, false));
      yield put(actionObject(GET_CLIENT_FINANCIAL_DETAIL_ASYNC, financialDetail));
    }

    if (!data) {
      yield put(actionObject(LOADING_CLIENTS_GET, false));
      return response?.message;
    }
  } catch (error) {
    yield put(actionObject(LOADING_CLIENTS_GET, false));
    yield put(actionObject(GET_CLIENT_FINANCIAL_DETAIL_ASYNC, { financialDetail: {} }));
    yield call(showDialog, 'Ha ocurrido un error', 'error');
  }
}

function* getClientStatiticsAsync({ payload }: any) {
  const { id } = payload;

  try {
    yield put(actionObject(LOADING_CLIENTS_GET, true));
    const {
      user: { accessToken },
    } = yield select(authSelector);

    const { data, statusCode, response } = yield call(
      FetchService,
      `/saving-bank/dashboard/${id}`,
      'GET',
      null,
      accessToken,
    );

    if (data && statusCode == 200) {
      yield put(actionObject(LOADING_CLIENTS_GET, false));
      yield put(actionObject(GET_CLIENT_STATISTICS_ASYNC, data));
    }

    if (!data) {
      yield put(actionObject(LOADING_CLIENTS_GET, false));
      return response?.message;
    }
  } catch (error) {
    yield put(actionObject(LOADING_CLIENTS_GET, false));
    yield call(showDialog, 'Ha ocurrido un error', 'error');
  }
}

function* setFinancialStatus({ payload }: any) {
  const { clientId, status, reason } = payload;

  try {
    yield put(actionObject(LOADING_CLIENTS_GET, true));
    const {
      user: { accessToken },
    } = yield select(authSelector);

    const body = { financialStatus: status, reason: reason || '' };

    const { data, statusCode, response } = yield call(
      FetchService,
      `/financial/status/${clientId}`,
      'PUT',
      body,
      accessToken,
    );

    if (data && statusCode == 200) {
      yield put(actionObject(LOADING_CLIENTS_GET, false));
      yield call(getClientsFinancialAsync, { payload: { id: clientId } });
      yield call(showDialog, 'Estado de la caja actualizado', 'success');
    }

    if (!data) {
      yield put(actionObject(LOADING_CLIENTS_GET, false));
      yield call(showDialog, 'Ha ocurrido un error', 'error');
      return response?.message;
    }
  } catch (error) {
    yield put(actionObject(LOADING_CLIENTS_GET, false));
    yield call(showDialog, 'Ha ocurrido un error', 'error');
  }
}

function* setLegalStatus({ payload }: any) {
  const { clientId, status, reason } = payload;

  try {
    yield put(actionObject(LOADING_CLIENTS_GET, true));
    const {
      user: { accessToken },
    } = yield select(authSelector);

    const body = { legalStatus: status, reason };

    const { data, statusCode, response } = yield call(
      FetchService,
      `/legal/status/${clientId}`,
      'PUT',
      body,
      accessToken,
    );

    if (data && statusCode == 200) {
      yield put(actionObject(LOADING_CLIENTS_GET, false));
      yield call(getClientsLegalAsync, { payload: { id: clientId } });
      yield call(showDialog, 'Estado de la caja actualizado', 'success');
    }

    if (!data) {
      yield put(actionObject(LOADING_CLIENTS_GET, false));
      yield call(showDialog, 'Ha ocurrido un error', 'error');
      return response?.message;
    }
  } catch (error) {
    yield put(actionObject(LOADING_CLIENTS_GET, false));
    yield call(showDialog, 'Ha ocurrido un error', 'error');
  }
}

function* setNotification({ payload }: any) {
  const { clientId, status } = payload;

  try {
    yield put(actionObject(LOADING_CLIENTS_GET, true));
    const {
      user: { accessToken },
    } = yield select(authSelector);

    const body = { status: status };

    const { data, statusCode, response } = yield call(
      FetchService,
      `/saving-bank/notification/${clientId}`,
      'PUT',
      body,
      accessToken,
    );

    if (data && statusCode == 200) {
      yield put(actionObject(LOADING_CLIENTS_GET, false));
      yield call(showDialog, 'Caja notificada', 'success');
    }

    if (!data) {
      yield put(actionObject(LOADING_CLIENTS_GET, false));
      yield call(showDialog, 'Ha ocurrido un error', 'error');
      return response?.message;
    }
  } catch (error) {
    yield put(actionObject(LOADING_CLIENTS_GET, false));
    yield call(showDialog, 'Ha ocurrido un error', 'error');
  }
}

function* getMonthlyDetail({ payload }: any) {
  const { id, page = 1 } = payload;

  try {
    yield put(actionObject(LOADING_CLIENTS_GET, true));
    const {
      user: { accessToken },
    } = yield select(authSelector);

    const { data, statusCode, response } = yield call(
      FetchService,
      `/monthly-upload/detail/${id}?page=${page}`,
      'GET',
      null,
      accessToken,
    );

    if (data && statusCode == 200) {
      yield put(actionObject(LOADING_CLIENTS_GET, false));
      yield put(actionObject(GET_CLIENT_MONTHLY_DETAIL_ASYNC, { ...data.data, isUpdated: data.isUpdated }));
    }

    if (!data) {
      yield put(actionObject(LOADING_CLIENTS_GET, false));
      return response?.message;
    }
  } catch (error) {
    yield put(actionObject(LOADING_CLIENTS_GET, false));
    yield put(actionObject(GET_CLIENT_MONTHLY_DETAIL_ASYNC, { monthlyDetail: {} }));
    yield call(showDialog, 'Ha ocurrido un error', 'error');
  }
}

function* searchReportDetailAsync({ payload }: any) {
  const { name, id, customPage } = payload;

  try {
    const {
      user: { accessToken },
    } = yield select(authSelector);
    const { data, statusCode, response } = yield call(
      FetchService,
      `/monthly-upload/detail/${id}?page=1&criteria=${name}`,
      'GET',
      undefined,
      accessToken,
    );

    if (data && statusCode == 200) {
      customPage(1);
      yield put(actionObject(SEARCH_REPORT_DETAIL_ASYNC, { ...data.data, monthlyDetail: data.isUpdated }));
    }

    if (!data) {
      throw new Error(response?.message);
    }
  } catch (error) {
    yield call(showDialog, 'Ha ocurrido un error', 'error');
  }
}

function* showMessage() {
  yield call(showDialog, 'Aún no posee aprobación para ingresar', 'error');
}

export function* watchGetClients() {
  yield takeLatest(GET_CLIENTS, getClientsAsync);
  yield takeLatest(GET_CLIENT_LEGAL_DETAIL, getClientsLegalAsync);
  yield takeLatest(GET_CLIENT_FINANCIAL_DETAIL, getClientsFinancialAsync);
  yield takeLatest(GET_CLIENT_STATISTICS, getClientStatiticsAsync);
  yield takeLatest(SET_FINANCIAL_STATUS, setFinancialStatus);
  yield takeLatest(SET_LEGAL_STATUS, setLegalStatus);
  yield takeLatest(SEARCH_CLIENTS, searchClientsAsync);
  yield takeLatest(SET_NOTIFICATION, setNotification);
  yield takeLatest(GET_CLIENT_MONTHLY_DETAIL, getMonthlyDetail);
  yield takeLatest(SEARCH_REPORT_DETAIL, searchReportDetailAsync);
  yield takeLatest(SHOW_MESSAGE, showMessage);
}
